<template>
<div>

	<section class="wrapper image-wrapper bg-cover bg-image bg-xs-none  py-15 "
					 style=" background-image: url(https://coffee.berlcoin.berlin/assets/img/berlcoin/bg37.jpg)">
		<div class="container pt-7 pb-12 py-sm-12 py-xxl-12">
			<h4 class="text-red"
					v-html="$t('offer1_up')" />
			<div class="row">

				<div class="col-lg-6 position-relative">
					<h2 class="display-4 mb-3"
							v-html="$t('offer1_title')" />

					<p class="lead"
						 style="max-width:620px;">
						<br />
						{{$t('offer1_line2')}}

						<br /> <br />
						{{$t('offer1_line1')}}
						<br /> <br />{{$t('offer1_line3')}}
					</p>

					<div>
						<a :href="$t('offer1_link')"
							 class="btn btn-lg  dark rounded mt-5 mt-10"
							 style="background-color: #1E294C; color:white">{{$t('offer1_btn')}}</a>
					</div>
				</div>
				<!--/column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>



	<section class="wrapper  ">
		<div class="container py-14 py-md-16">
			<div class="row gx-lg-8 gx-xl-12 gy-12 align-items-center   pb-6">
				<div class="col-lg-6 position-relative">
					<div class="btn btn-circle btn-primary disabled position-absolute counter-wrapper flex-column d-none d-md-flex"
							 style="top: 50%; left: 50%; transform: translate(-50%, -50%); width: 170px; height: 170px;">
						<h3 class="text-white mb-1 mt-n2"><span class="counter counter-lg">6.1%</span></h3>
						<p>2022</p>
					</div>
					<div class="row gx-md-5 gy-5 align-items-center">
						<div class="col-md-6">
							<div class="row gx-md-5 gy-5">
								<div class="col-md-10 offset-md-2">
									<figure class="rounded"><img src="https://media.berlcoin.fr/f4.jpg"
												 srcset="https://media.berlcoin.fr/f4.jpg"
												 alt=""></figure>
								</div>
								<!--/column -->
								<div class="col-md-12">
									<figure class="rounded"><img src="https://media.berlcoin.fr/f3.jpg"
												 srcset="https://media.berlcoin.fr/f3.jpg"
												 alt=""></figure>
								</div>
								<!--/column -->
							</div>
							<!--/.row -->
						</div>
						<!--/column -->
						<div class="col-md-6">
							<figure class="rounded"><img src="https://media.berlcoin.fr/f4.jpg"
										 srcset="https://media.berlcoin.fr/f2.jpg"
										 alt=""></figure>
						</div>
						<!--/column -->
					</div>
					<!--/.row -->
				</div>
				<!--/column -->
				<div class="col-lg-6">
					<h4 class="text-red"
							v-html="$t('offer2_up')" />
					<h2 class="display-4 mb-3"
							v-html="$t('offer2_title')" />

					<br /><b>{{$t('offer2_bold1')}}</b>
					: {{$t('offer2_line1')}}

					<br />
					<br /><b>{{$t('offer2_bold2')}}</b>
					: {{$t('offer2_line2')}}
					<br />
					<br /><b>{{$t('offer2_bold3')}}</b>
					: {{$t('offer2_line3')}}
					<br />
					<br /><b>{{$t('offer2_bold4')}}</b>
					: {{$t('offer2_line4')}}

					<br />
					<div class="row counter-wrapper gy-6">

						<!--/column -->
						<div class="col-md-4">
							<a :href="$t('offer2_link')"
								 class="btn btn-lg  dark rounded mt-5 mt-10"
								 style="background-color: #1E294C; color:white">{{$t('offer2_btn')}}</a>
						</div>
						<!--/column -->
					</div>
					<!--/.row -->
				</div>
				<!--/column -->
			</div>
			<!--/.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->
	<section class="wrapper bg-gray bg-cover bg-image bg-xs-none  py-15 ">
		<div class="container pt-7 pb-12 py-sm-12 py-xxl-12">
			<h4 class="text-red"
					v-html="$t('nftpatrimoine_up')" />
			<div class="row">

				<div class="col-lg-6 position-relative">
					<h2 class="display-4 mb-3"
							v-html="$t('nftpatrimoine_title')" />
					<p class="lead ">
						<br />
						{{$t('nftpatrimoine_line1')}}
						<br /> <br /> {{$t('nftpatrimoine_line2')}}
					</p>
					<p>
						{{$t('nftpatrimoine_line3')}}
					</p>
					<div>
						<a href="https://berlcoin.market"
							 class="btn btn-lg  dark rounded mt-5 mt-10"
							 style="background-color: #1E294C; color:white">Acheter des cartes</a>

					</div>
					<!--/column -->
				</div>
				<div class="col-lg-6">
					<v-img class="  rounded "
								 src="https://media.berlcoin.fr/BER0001x008.png"
								 alt="" />
				</div>

				<!--/column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>

	<forminsert />

</div>
</template>


<script>
import forminsert from "@/components/form_insert.vue";

export default {
	name: 'offer',
	components: {
		forminsert
	},
	props: {

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>
